@mixin thumnbail__label($sz: 8px) {
  font-size: $sz / 2; /* 50% of parent */
  line-height: 1;
  position: relative;
  top: $sz / 4;
}

.thumb48__label {
  @include thumnbail__label(48px);
}

.thumb32__label {
  @include thumnbail__label(30px);
}

.content-wrapper .content-heading label {
  color: #656565;
  font-size: 0.875rem;
  line-height: 1.52857;
}

.ReactTable .rt-thead.-header {
  text-transform: uppercase;
  font-weight: bold;
}

.nav-tabs > .nav-item > .nav-link {
  font-weight: 500;
}

.nav-tabs > .nav-item > .nav-link.active {
  font-weight: bold;
}

.badge.badge-custom {
  width: 100%;
  font-size: 0.8rem;
}

.badge.badge-yellow {
  color: #6e6a6a;
  background-color: #fad732;
}

.swal-footer {
  text-align: center;
}

.swal-button--confirm {
  background-color: #3085d6;
}

.swal-button--confirm:not([disabled]):hover {
  background-color: #3085d6;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtDay.in-selecting-range {
  background-color: rgba(66, 139, 202, 0.4);
}

div.rdt > input.form-control[readonly] {
  background-color: #fff;
}

.rdtPicker button.today {
  color: #fff;
  padding: 1px 6px;
  border: 1px solid transparent;
  border-radius: 3px;
  background-color: #5d9cec;
  border-color: #5d9cec;
  &:hover {
    color: #fff;
    background-color: #3b87e8;
    border-color: #2f80e7;
  }
}

.form-ref-control {
  padding: 0.375rem;
  display: block;
}
//
span.highlight {
  font-weight: bold;
  color: red;
}

/***/
.react-autosuggest__container {
  position: relative;
}

// .react-autosuggest__input {
//   width: 240px;
//   height: 30px;
//   padding: 10px 20px;
//   font-family: Helvetica, sans-serif;
//   font-weight: 300;
//   font-size: 16px;
//   border: 1px solid #aaa;
//   border-radius: 4px;
// }

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  max-height: 200px;
  top: 45px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  // font-family: Helvetica, sans-serif;
  font-weight: 400;
  // font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  overflow: auto;
  white-space: nowrap;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.swal2-header .swal2-title {
  overflow-wrap: anywhere;
}

.custom-file-label {
  overflow: hidden;
}

div.editable {
  width: auto;
  height: 100px;
  border: 1px solid #ccc;
  padding: 5px;
  resize: both;
  overflow: auto;
}
#oligrapher-container div#oligrapher-header {
  width: 100%;
}

div#oligrapher-graph-svg,
svg#oligrapher-svg {
  height: 100% !important;
}
#oligrapher-container #oligrapher-header h1#oligrapher-title {
  font-size: 20px;
  &,
  & input {
    font-size: 20px;
    height: 32px;
  }
}
#oligrapher-container {
  #oligrapher-header,
  #oligrapher-header-condensed {
    #oligrapher-header-subtitle-wrapper {
      &,
      & input {
        font-size: 15px;
      }
    }
  }
}
.object-col {
  width: 50%;
}
.nav-tabs > .nav-item.vertical-tab > a.nav-link {
  background-color: #fff;
  border: 0;
  &.active {
    background-color: #edf1f2;
  }
}

//    background-color: #edf1f2;
.below-table .records-info {
  font-weight: bold;
  font-style: italic;
}

span.form-control.disabled {
  background-color: #edf1f2;
  opacity: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.watchlist-warning {
  background-color: red !important;
  color: white;
}

.watchlist-primary {
  background-color: #5d9cec !important;
  color: white;
}
.rdtOpen .rdtPicker {
  font-size: 0.875rem;
}
@media (max-width: 538px) {
  .layout-fixed .wrapper .section-container {
    margin-top: 118px;
  }
  .wrapper .aside-container .aside-inner {
    padding-top: 117px;
  }
}
/*MODAL*/
.modal-header {
  cursor: move;
}
.modal-title {
  cursor: auto;
}
.modal.modal-fullscreen {
  padding: 0 !important; // override inline padding-right added from js
  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
}
/*END MODAL*/
.form-date-range {
  display: contents;
}

body:not(.modal-open) {
  padding-right: 0px !important;
}

.tableFixHead {
  overflow: auto;
  max-height: 280px;
}
.tableFixHead thead th {
  position: sticky;
  top: -1px;
  z-index: 1;
  background: #fff;
}

/// LABEL INPUT GROUP
.label-group-relative {
  i {
    position: absolute;
    right: 5px;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
      opacity: 1;
      font-size: 13px;
    }
  }
}

.form-control.has-changed,
.form-select2.has-changed .select2-selection__control,
.form-check-input.has-changed,
.checkbox .has-changed {
  // border-radius: 50%;
  // border-color: #ecd358 !important;
  // box-shadow: 0 0 6px #ecd358 !important;
}

// TEXT-FIELD
.floating-form-group {
  position: relative;
  border-bottom: 1px solid #37343430;
  padding-bottom: 5px;
  margin-bottom: 1rem;

  i {
    top: 50%;
    right: 12px;
    position: absolute;
    opacity: 0.5;
    cursor: pointer;
  }

  i {
    &:hover {
      opacity: 1;
    }
  }

  .floating-label {
    text-transform: capitalize;
    font-weight: 400;
    font-size: 13px;
  }

  .floating-text-field {
    color: #495057;
    font-weight: 400;
    font-size: 14px;
  }
}

// REACT_TABLE
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #5d9cec !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #5d9cec !important;
}

div.input-wrapper {
  padding-bottom: 16px;

  label {
    font-size: 13px;
  }
}
div.input-wrapper.has-changed {
  background-color: #fef3964d;
  // box-shadow: 0 0 26px #f1c800ad !important;
}

/*DISABLED CHECKBOX*/
// .c-view-mode {
.c-checkbox input[type="checkbox"]:checked:disabled + span,
.c-checkbox input[type="radio"]:checked:disabled + span,
.c-radio input[type="checkbox"]:checked:disabled + span,
.c-radio input[type="radio"]:checked:disabled + span {
  border-color: #5d9cec !important;
  background-color: #5d9cec !important;
}
// }

.card-body {
  overflow: auto;
}

div.timeline-modal > div.popover {
  position: relative;
}

div.timeline-separator {
  display: inline-block;
  width: 120px;
  text-align: center;
  padding: 0 20px;
  line-height: 30px;
  background-color: #b6c5da;
  color: #fff;
}

/*CASE  -- - -- -- TRANSACTION DETAIL*/
table.custom-table-monitoring {
  th {
    font-weight: bold;
    color: black !important;
  }
  td.field-selected {
    background-color: yellow;
    min-width: 50px;
  }
  td {
    padding: 0.2rem 0.3rem;
  }
}

table.custom-table-transaction-monitoring {
  th {
    font-weight: bold;
    color: black !important;
    padding: 0;
  }
  td.field-selected {
    background-color: yellow;
    min-width: 20px;
  }
  td {
    padding: 0.2rem 0.3rem;
    border-top: none;
  }
}

/*NAV BUTTON*/
.btn.nav-button.disabled {
  background-color: #dfdfdf;
  font-weight: bold;
}
div[role="menu"].demo-menu {
  visibility: hidden;
}

div[role="menu"].demo-menu.visible {
  visibility: visible;
}
.demo-menu {
  background: #fff;
  padding: 0.8rem 1rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  box-shadow: 0 0.2rem 0.4rem #0000001a;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.2s, opacity 0.2s, visibility 0s linear 0.2s;
  will-change: transform;
  position: absolute;
  width: fit-content;
  left: 0;
  right: 0;
  margin: 0.8rem auto auto;
  z-index: 2;
}
.demo-menu.visible {
  transform: translateY(-0.3rem);
  transition: transform 0.2s, opacity 0.2s, visibility linear;
  visibility: visible;
  opacity: 1;
}

.popover-body {
  button.dropdown-item {
    white-space: normal;
    padding: revert;

    // height: 0;
    margin: 0.5rem 0;
    // overflow: hidden;
    border-top: 1px solid #e9ecef;

    &:first-child {
      border-top: none;
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

// .popover-body > button.dropdown-item:first-child {
//   border-top: none;
// }

.popover {
  z-index: 1050;
  box-shadow: 0 6px 12px #0000002e;
}

.event-name-text-field {
  word-break: break-all;
}

.action-group-btn {
  .btn {
    margin: 0 4px 4px 0;
  }
}

.nav-item {
  .input-wrapper {
    padding: 0;
  }
}
.oligrapher {
  height: 90vh;
}

legend {
  display: block;
  width: auto;
  max-width: 100%; // 1
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: initial !important;
  line-height: inherit;
  color: inherit; // 2
  white-space: normal; // 1
}

//Ẩn icon Calender
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="date"]::-moz-calendar-picker-indicator {
  display: none;
}

input[type="date"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.graph-properties {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.graph-properties::-webkit-scrollbar {
  display: none;
}

.carousel-caption-black {
  // position: absolute;
  right: (30%) / 2;
  bottom: 20px;
  left: (30%) / 2;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: black;
  text-align: center;
}

.bg-statistic-alert-dark {
  background-color: #f1b34c;
  color: #fff !important;
}

.bg-statistic-alert {
  background-color: #ffcc78;
  color: #fff !important;
}

mark,
.mark {
  color: black;
  background-color: yellow;
}

.customTooltip {
  position: relative;
  display: inline-block;
}

.customTooltip .customTooltiptext {
  // visibility: hidden;
  width: 150px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0px 5px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 140%;
  margin-left: -60px;
  transition: opacity 0.3s;
}

.customTooltip .customTooltiptext::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 100%;
  margin-left: -6px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent #555;
}

.customTooltip:hover .customTooltiptext {
  // visibility: visible;
  opacity: 1;
}

/* arrow steps */

.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}

.arrow-steps .step {
  // font-size: 14px;
  text-align: center;
  color: #777;
  cursor: default;
  margin: 0 1px 0 0;
  padding: 8px 0px 8px 0px;
  width: 10%;
  float: left;
  position: relative;
  background-color: #ddd;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.arrow-steps .step a {
  color: #777;
  text-decoration: none;
  display: block;
  width: 100%;
  padding-left: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: "";
  position: absolute;
  top: 0;
  right: -15px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 18px solid transparent;
  border-left: 17px solid #ddd;
  z-index: 2;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #fff;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step:last-child:after {
  // border: none;
}

.arrow-steps .step:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.arrow-steps .step:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.arrow-steps .step span {
  position: relative;
}

*.arrow-steps .step.done span:before {
  opacity: 1;
  content: "";
  position: absolute;
  top: -2px;
  left: -10px;
  font-size: 11px;
  line-height: 21px;
}

.arrow-steps .step.current {
  color: #fff;
  background-color: #4f88d1;
}

.arrow-steps .step.current:hover {
  color: #fff;
  background-color: #3866a0;
}

.arrow-steps .step.current a {
  color: #fff;
  text-decoration: none;
}

.arrow-steps .step.current:after {
  border-left: 17px solid #4f88d1;
}

.arrow-steps .step.current:hover:after {
  border-left: 17px solid #3866a0;
}

.arrow-steps .step.done {
  // color: #173352;
  color: #fff;
  background-color: #c2c2c2;
}

.arrow-steps .step.done:hover {
  // color: #173352;
  color: #fff;
  background-color: #989898;
}

.arrow-steps .step.done a {
  color: #7a7a7a;
  // color: #173352;
  text-decoration: none;
}

.arrow-steps .step.done a:hover {
  color: #fff;
  // color: #173352;
  text-decoration: none;
}

.arrow-steps .step.done:after {
  border-left: 17px solid #c2c2c2;
}

.arrow-steps .step.done:hover:after {
  border-left: 17px solid #989898;
}

.arrow-steps .step.compeleted {
  // color: #173352;
  color: #fff;
  background-color: #4aaf62;
}

.arrow-steps .step.compeleted:hover {
  // color: #173352;
  color: #fff;
  background-color: #3f9b55;
}

.arrow-steps .step.compeleted a {
  color: #fff;
  // color: #173352;
  text-decoration: none;
}

.arrow-steps .step.compeleted:after {
  border-left: 17px solid #4aaf62;
}

.arrow-steps .step.compeleted:hover:after {
  border-left: 17px solid #3f9b55;
}

.radientButtonViewChangedBlue {
  width: 3px;
  height: 15px;
  border: 1px solid #179dc4;
  background-color: #23b7e5;
  background-image: linear-gradient(
    0deg,
    #23b7e5 0%,
    #abe4f5 50%,
    #23b7e5 100%
  );
  box-shadow: #23b7e58c 0px 0px 5px 2px;
  overflow: hidden;
  border-radius: 15px;
}

.radientButtonViewChangedGray {
  width: 3px;
  height: 15px;
  background-color: gray;
  background-image: linear-gradient(
    0deg,
    grey 0%,
    rgb(181, 181, 181) 50%,
    grey 100%
  );
  border-radius: 15px;
  overflow: hidden;
}

.table-auto-break-line {
  .rt-td {
    white-space: normal !important;
  }
}

///TreeView Process CSS
///
$light-colors: (
  noapp: #999999,
  hr: #ff8360,
  revenue: #cd4e9d,
  calendar: #5c8eca,
  doc: #2085aa,
  mc: #2085aa,
  crm: #dd8447,
  settings: #cc474f,
  bills: #3ca09d,
  accounting: #e2bb62,
  customers: #dd8447,
  reporting: #b280ea,
  good: #3dbd90,
  neutral: #bc8d3f,
  bad: #cc474f,
);

$dark-colors: (
  noapp: #666666,
  hr: #d16c59,
  revenue: #b04387,
  calendar: #3a597e,
  doc: #115d78,
  mc: #115d78,
  crm: #c46627,
  settings: #a83b42,
  bills: #2c8484,
  accounting: #ddb047,
  customers: #c46627,
  reporting: #936bc1,
  good: #2b8666,
  neutral: #ac7513,
  bad: #a83b42,
);

$grid: 20px;
$colorGray: #ccc;
$transitionTime: 0.3s;

// TODO remove tree-view
.tree-view {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $colorGray;
  transition: border-color 0.3s;
  width: 100%;
  height: 100%;
  background-color: #fff;
  flex-direction: column;
}

@mixin pseudoStyles($width, $height) {
  content: "";
  display: block;
  width: $width;
  height: $height;
  background-color: $colorGray;
  position: absolute;
  z-index: 20;
  transition: background-color $transitionTime;
}

.tree-items {
  display: flex;
  flex-direction: column;
  min-width: 1000px;

  .item {
    display: flex;

    &__parent {
      position: relative;
      min-width: 150px;

      &__line {
        position: absolute;
        width: 1px;
        background-color: $colorGray;
        right: calc(100% + #{$grid});
        z-index: 10;
        transition: background-color $transitionTime;
      }

      &__element {
        min-width: 200px;
        width: 100%;
        margin-bottom: $grid / 2;
        display: flex;
        align-items: center;

        &.has-children:after {
          @include pseudoStyles($grid, 1px);
          left: 100%;
        }

        &.has-parent:before {
          @include pseudoStyles($grid, 1px);
          right: 100%;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.active {
        .item__parent__element {
          &.has-children.no-active-children:after {
            background-color: $colorGray;
          }
        }
      }
    }

    &__children {
      margin-left: $grid * 2;
      position: relative;
      flex: 1;
    }
  }
}

@each $module, $color in $light-colors {
  body.currentapp_#{$module} {
    .hr-teams {
      .item {
        &__parent {
          &__line {
            &.active {
              background-color: $color;
            }
          }

          &.active {
            .item__parent__element {
              border-color: $color;

              &.has-parent:before,
              &.has-children:after {
                background-color: $color;
              }
            }

            .tree-view {
              border-color: $color;
            }
          }
        }

        &__children.dragged-over {
          background-color: rgba($color, 0.1);
        }
      }
    }
  }
}

@each $module, $color in $dark-colors {
  body.currentapp_#{$module} {
    .hr-teams {
      .item {
        &__parent {
          &__line {
            &.active {
              background-color: $color;
            }
          }

          &.active {
            .item__parent__element {
              border-color: $color;

              &.has-parent:before,
              &.has-children:after {
                background-color: $color;
              }
            }

            .tree-view {
              border-color: $color;
            }
          }
        }

        &__children.dragged-over {
          background-color: rgba($color, 0.1);
        }
      }
    }
  }
}

.custom-sticky-table .rt-thead.-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.custom-sticky-table {
  position: relative;
  max-height: 500px;
  overflow-y: "scroll";
}
