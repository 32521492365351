/* ========================================================================
     Component: history
 ========================================================================== */

$history-badge-size : 36px;
$history-datetime-height : 20px;
$history-separator-width : 120px;

// Timeline container
// --------------------------------
.history,
.history-alt {
    list-style: none;
    padding: 0 0 20px;
    position: relative;
    z-index: 0; // Timeline central line
    &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        content: " ";
        width: 1px;
        background-color: #d1d2d3;
        left: $history-badge-size / 2;
    } // clear all float element using a last item
    .history-end {
        float: none;
        clear: both;
    } // change popover default colors to use no title
    .popover {
        &.left>.arrow:after {
            border-left-color: #fff;
        }
        &.right>.arrow:after {
            border-right-color: #fff;
        } // but restore the color if we use a title
        &.left>.popover-title+.arrow:after {
            border-left-color: $popover-arrow-color;
        }
        &.right>.popover-title+.arrow:after {
            border-right-color: $popover-arrow-color;
        }
    } // Timeline list itmes
    // --------------------------------
    >li {
        @include clearfix();
        margin-bottom: 20px;

        &.history-separator {
            position: relative;
            float: none;
            clear: both;
            margin: 0 auto;
            padding: 40px 0;
            &:first-child {
                padding-top: 0;
            }
            &:before {
                content: attr(data-datetime);
                display: inline-block;
                width: $history-separator-width;
                text-align: center;
                padding: 0 20px;
                line-height: 30px;
                background-color: #b6c5da;
                color: #fff;
            }
        }
        &.history-end-history {
            position: absolute;
            float: none;
            clear: both;
            margin: 0 auto;
            padding: 40px 0;
            &:first-child {
                padding-top: 0;
            }
            width: 200px;
            color: #fff;
            background-color: #b6c5da;
            padding: 0 20px;
            line-height: 30px;
            // &:before {
            //     content: attr(data-datetime);
            //     display: inline-block;
            //     width: 200px;
            //     text-align: center;
            //     padding: 0 20px;
            //     line-height: 30px;
            //     background-color: #b6c5da;
            //     color: #fff;
            // }
        }
    } // Timeline card
    // --------------------------------
    >li>.history-card {
        margin-left: $history-separator-width / 2; // Popover inside history cards
        .popover {
            position: relative;
            display: block;
            margin: 0;
            width: 100%;
            max-width: none;
            .arrow {
                top: $history-badge-size/2;
                display: none;
            }
            .popover-content {
                .popover-title {
                    background-color: transparent;
                    border: 0;
                    padding-left: 0;
                    padding-top: 0;
                }
            }
        }
    } // Timeline Date
    // --------------------------------
    >li>.history-date {
        margin: -$history-datetime-height 0 0;
        display: block;
        height: $history-datetime-height;
        line-height: $history-datetime-height;
        font-size: 13px;

        >time {
            &:after {
                content: attr(datetime);
            }
        }
    } // Timeline badges
    // --------------------------------
    >li>.history-badge {
        position: absolute;
        left: 0;
        width: $history-badge-size;
        height: $history-badge-size;
        border-radius: 500px;
        line-height: $history-badge-size;
        font-size: 1em;
        text-align: center;
        color: #fff;
        background-color: #999;
        z-index: 100; // Color variants
        &.primary {
            background-color: $primary;
        }
        &.success {
            background-color: $success;
        }
        &.warning {
            background-color: $warning;
        }
        &.danger {
            background-color: $danger;
        }
        &.info {
            background-color: $info;
        }

        &.inverse {
            background-color: $inverse;
        }
        &.green {
            background-color: $green;
        }
        &.pink {
            background-color: $pink;
        }
        &.purple {
            background-color: $purple;
        }
        &.dark {
            background-color: $dark;
        }
        &.yellow {
            background-color: $yellow;
        }
    }
}

.history-alt {
    .popover .arrow {
        display: block !important;
    }
}

// Timeline title
// --------------------------------
.history-title {
    margin-top: 0;
    color: inherit;
}

// Timeline Body
// --------------------------------
.history-body>p,
.history-body>ul {
    margin-bottom: 0;
}

.history-body>p+p {
    margin-top: 5px;
}

// Timeline for Desktops
// --------------------------------

@include media-breakpoint-up(lg) {

    .history {
        &:before {
            left: 60%;
            margin-left: ($history-badge-size/2);
        }
        >li {
            float: left;
            clear: left;
            width: 55%;

            &.history-inverted {
                float: right;
                clear: right;
                width: 35%;
                margin-top: 50px;
            }
        }

        >li.history-separator {
            text-align: center;
            left: 10%;
            &:before {
                margin-left: ($history-badge-size);
            }
        }

        >li.history-end-history {
            text-align: center;
            position: relative;
            left: calc(10% + 18px);
            &:before {
                margin-left: ($history-badge-size);
            }
        }

        >li>.history-badge {
            left: 60%;
            margin-left: 0;
        }

        >li>.history-date {
            float: none;
            position: absolute;
            width: 40%;
            left: 50%;
            top: $history-badge-size / 2;
            margin-left: $history-badge-size * 1.5;
            margin-top: -($history-datetime-height/2);
        }

        >li>.history-card {
            margin: 0;

            &:before {
                border-left-width: 15px;
                border-right-width: 0;
                top: 26px;
                right: -15px;
                left: auto;
            }

            &:after {
                border-left-width: 14px;
                border-right-width: 0;
                top: 27px;
                right: -14px;
                left: auto;
            }

            .popover .arrow {
                display: block;
            }
        } // Timeline inverted
        // --------------------------------
        >li.history-inverted {

            >.history-card {
                padding-left: $history-badge-size;
            }

            .history-date {
                left: auto;
                right: 50%;
                width: auto;
                margin-left: 0;
                margin-right: $history-badge-size;
            }
        }
    }
}
